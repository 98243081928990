import React from 'react'
import propType from 'prop-types'
import Fade from 'react-reveal/Fade'

import { FeaturesHeroWave } from 'src/components/Svg'

import s from './pricingHero.module.scss'

const PricingHero = ({ title, image }) => (
  <div className={s.heroSection}>
    <Fade top cascade>
      <FeaturesHeroWave className={s.heroIcon} />
    </Fade>

    <div className={s.wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-7 col-lg-7">
            <Fade left cascade>
              <div className={s.left}>
                <h1 className={s.title}>{title[0].text}</h1>
              </div>
            </Fade>
          </div>
          <div className="col-md-5 col-lg-5">
            <Fade right cascade>
              <img src={image.url} className={s.bisFlowMainImage} alt={image.alt} />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  </div>
)

PricingHero.propTypes = {
  title: propType.array,
  image: propType.object
}

export default PricingHero
