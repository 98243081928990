import React, { useState, useEffect } from 'react'
import cx from 'clsx'
import Slider from 'react-slick'
import queryString from 'query-string'

import Switch from 'src/components/Switch'
import { ChatIcon } from 'src/components/Svg'
import ContactUsForm from 'src/components/ContactUsForm'
import PaymentForm from 'src/components/PaymentForm'

import s from './pricingPlan.module.scss'

const settings = { infinite: false, speed: 500, slidesToShow: 3, slidesToScroll: 3, dots: true }
settings.responsive = [
  {
    breakpoint: 1200,
    settings: { slidesToShow: 2, slidesToScroll: 2 }
  },
  {
    breakpoint: 600,
    settings: { slidesToShow: 1, slidesToScroll: 1 }
  }
]

const Plan = ({ title, value, valueText, submitText, onSubmit, footerText, children, dynamicValue }) => {
  return (
    <div className={s.plan}>
      {title ? <h3 className={s.heading}>{title}</h3> : null}

      <div className={s.box}>
        <div className={s.inner}>
          <div className={s.amount}>
            <span className={s.value}>{value}</span>
            {valueText ? <span className={s.text}>{valueText}</span> : null}
          </div>

          {children ? <div className={s.content}>{children}</div> : null}

          {submitText ? <div className={s.btn}>
            <button className="btn btn-primary" onClick={onSubmit}>
              {submitText}
            </button>
          </div> : null}

          {dynamicValue ? <span className={s.dynamicValue}>{dynamicValue}</span> : null}

          <p className={s.foot}>{footerText}</p>
        </div>
      </div>
    </div>
  )
}

const PricingPlan = () => {
  const [payType, setPayType] = useState('monthly') // or annually
  const [inputValue, setInputValue] = useState(20)
  const [openPopup, setOpenPopup] = useState('') // contact or payment
  const [proValue, setProValue] = useState(1600)
  const coefficient = payType === 'monthly' ? 100 : 80

  const handleSwitch = () => {
    if (payType === 'monthly') setPayType('annually')
    else setPayType('monthly')
  }

  const handleProValueChange = value => value > 2 && setInputValue(value)

  useEffect(() => {
    setProValue(inputValue * coefficient)
  } , [inputValue, payType]) // eslint-disable-line

  const handleOpenPopup = val => setOpenPopup(val)

  const handleSubmitFreePlan = () => {
    const parsed = {
      plan: 'free',
      'pay-type': payType
    }

    const stringified = queryString.stringify(parsed)

    window.location.replace(`https://app.bisflow.com/signup?${stringified}`)
  }

  const handleSubmitProPlan = () => {
    const parsed = {
      plan: 'pro',
      'pay-type': payType,
      'members-count': inputValue
    }

    const stringified = queryString.stringify(parsed)

    window.location.replace(`https://app.bisflow.com/signup?${stringified}`)
  }

  return (
    <>
      <div className={s.wrapper}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={s.head}>
                <h2 className={s.title}>Pay Monthly</h2>

                <div className={s.switchWrapper}>
                  <Switch isOn={payType === 'monthly'} onClick={handleSwitch} />
                </div>

                <h2 className={s.title}>
                  Pay Annually
                  <span>(SAVE 20%)</span>
                </h2>
              </div>
            </div>
          </div>

          <div className={cx('row', s.sliderRow)}>
            <Slider class={s.slickWrapper} {...settings}>
              <div className={s.planWrapper}>
                <Plan
                  title="Free"
                  value={0}
                  valueText="SEK forever"
                  submitText="Get Started"
                  onSubmit={handleSubmitFreePlan}
                  footerText="A simple way to get started with BisFlow"
                >
                  <ul className={s.list}>
                    <li>2 Projects</li>
                    <li>2 Members</li>
                  </ul>
                </Plan>
              </div>

              <div className={s.planWrapper}>
                <Plan
                  title="Pro"
                  value={coefficient}
                  valueText={<><span>SEK / per member </span>{' '}<span>per month</span></>}
                  submitText="Buy Pro"
                  onSubmit={handleSubmitProPlan}
                  footerText="For small or medium teams working on several projects"
                  dynamicValue={<>Total <strong>${proValue}</strong> SEK / per month (billed ${payType === 'monthly' ? 'monthly' : 'annually'})</>}
                >
                  <ul className={s.list}>
                    <li>Unlimited Projects</li>
                    <li>
                      <div className={s.input}>
                        <span role="presentation" className={s.leftMinus} onClick={() => handleProValueChange(Number(inputValue) - 1)}>-</span>
                        <input type="number" value={inputValue} onChange={e => handleProValueChange(e.target.value)} />
                        <span role="presentation" className={s.rightPlus} onClick={() => handleProValueChange(Number(inputValue) + 1)}>+</span>
                      </div>
                      Members
                    </li>
                  </ul>
                </Plan>
              </div>

              <div className={s.planWrapper}>
                <Plan
                  title="Enterprise"
                  value={<ChatIcon />}
                  valueText={null}
                  submitText="Contact Us"
                  onSubmit={() => handleOpenPopup('contact')}
                  footerText="For large companies with several projects"
                >
                  <ul className={s.list}>
                    <li>Custom Pricing</li>
                    <li>Please Contact Us For More Info</li>
                  </ul>
                </Plan>
              </div>
            </Slider>
          </div>
        </div>

        <ContactUsForm
          onSubmit={formData => console.log('formData ->', formData)}
          hasCloseIcon
          onClose={() => handleOpenPopup('')}
          classes={{ popupRoot: cx(s.popupRoot, { [s.show]: openPopup === 'contact' }) }}
        />

        <PaymentForm
          onSubmit={formData => console.log('formData ->', formData)}
          hasCloseIcon
          onClose={() => handleOpenPopup('')}
          classes={{ popupRoot: cx(s.popupRoot, { [s.show]: openPopup === 'payment' }) }}
        />
      </div>
    </>
  )
}

export default PricingPlan
