import React from 'react'

import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'
import Hero from 'src/components/PricingHero'
import Details from 'src/components/PricingPlan'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { graphql } from "gatsby"

export const query = graphql`
  {
    prismic {
      pricing(lang: "en-us", uid: "pricing") {
        image
        title
      }
    }
  }
`

const PricingPage = props => {
  const data = props.data.prismic.pricing

  return (
    <Layout>
      <SEO title="Pricing" />

      <Hero
        title={data.title}
        image={data.image}
      />

      <Details />
    </Layout>
  )
}

export default PricingPage
