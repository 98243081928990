import React from 'react'
import cx from 'clsx'
import { motion } from 'framer-motion'

import s from './switch.module.scss'

const Switch = ({ isOn, ...props }) => {
  const className = cx(s.switch, { [s.on]: isOn }, { [s.off]: !isOn })

  return (
    <motion.div animate className={className} {...props}>
      <motion.div animate />
    </motion.div>
  );
}

export default Switch
